import van from "vanjs-core";

import { globalUser, isSignedIn, signOutUser } from "./globals";
import { formatNameForProfileIcon } from "./helpers/formatting";
import { signOut } from "firebase/auth";
import { route } from "./components/Router";
import { ProfileCircle } from "./components/ProfileCircle";

const { div, img, t, button, a } = van.tags;

const LandingHero = () => {
  const imageUrl = new URL(
    "../images/animated-logo-modded.gif",
    import.meta.url,
  );
  return div(
    { class: "w-full align-middle items-center flex flex-col" },
    div(
      {
        class:
          "flex sm:w-[50%] max-w-[1000px] w-full p-4 flex-grow-0 flex-col justify-start font-semibold font-formal text-dark sm:text-6xl text-4xl border-dark border-l-4 gap-2 relative",
      },
      div(
        { class: "flex w-full items-center flex-grow-0 h-40" },
        div(
          {
            class: "sm:w-[160px] sm:h-[160px] w-[140px] h-[140px] flex-grow-0",
          },
          img({
            src: imageUrl,
            class: "object-contain relative top-[10%]",
            alt: "Casenow logo",
          }),
        ),
        div(
          { class: "flex-grow w-full" },
          t({ class: "font-rope" }, "Casenow"),
        ),
      ),
      t(
        { class: "sm:text-2xl text-xl font-rope font-normal" },
        "Consulting calls you.",
      ),
      t(
        { class: "sm:text-2xl text-xl font-rope font-normal" },
        "A different way to prep.",
      ),
      t(
        { class: "sm:text-2xl text-xl font-rope font-normal" },
        "Your skill shines anew.",
      ),
      // () =>
      //   isSignedIn()
      //     ? div(
      //         { class: "w-full  flex justify-end absolute top-0 right-0" },
      //         ProfileCircle(),
      //       )
      //     : "",
    ),
  );
};

export default LandingHero;
