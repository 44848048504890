import van from "vanjs-core"
import {FeedbackRow} from "./FeedbackRow.js";
import PrimaryButton from "./PrimaryButton.js";
import { SmallLinkButton } from "./SmallLink.js";
import { isSignedIn, signInUserWithGoogle } from "../globals.js";
import Chart from 'chart.js/auto';
import { formatMessageForChat } from "../helpers/formatting.js";
import isMobileOrTablet from "../helpers/checkDevice.js";

const {div, h1, t, canvas, span} = van.tags;

const FeedbackDisplay = (feedbackData, mannerismData) => {

  function waitForElm(selector) {
      return new Promise(resolve => {
          if (document.querySelector(selector)) {
              return resolve(document.querySelector(selector));
          }

          const observer = new MutationObserver(mutations => {
              if (document.querySelector(selector)) {
                  observer.disconnect();
                  resolve(document.querySelector(selector));
              }
          });

          // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
          observer.observe(document.body, {
              childList: true,
              subtree: true
          });
      });
  }

  let responseTimesSaved = feedbackData.responseTimes;
  waitForElm("#chart").then((el) => {
    console.log("HERE NOW.", responseTimesSaved)

    const labels = Array.from({ length: responseTimesSaved.length + 1 }, (_, i) => i);
    const data = {
      labels: labels,
      datasets: [{
        label: 'Response Times (s)',
        data: responseTimesSaved,
        fill: false,
        borderColor: 'rgb(190, 208, 238)',
        tension: 0.1
      }]
    };
    new Chart(
      document.getElementById('chart'),
        {
          type: 'line',
          data: data,
          labels: labels,
          options: {
            responsive: false,
            scales: {
                y: {
                    beginAtZero: true
                }
            }
          }
        }
      );
  });

  let avgResponseTime = -1;
  let responseTimeDisplayed = "";
  if ("responseTimes" in feedbackData){
    avgResponseTime = 0;
    for (let x of feedbackData.responseTimes){
      avgResponseTime += x;
    }
    avgResponseTime /= feedbackData.responseTimes.length;
    delete feedbackData.responseTimes;
  }
  if (avgResponseTime != -1){
    responseTimeDisplayed = div({class: "flex justify-center"},
      h1(
        {class: "font-rope font-semibold text-xl"},
        `Average Response Time: ${avgResponseTime.toFixed(2)} s`
      ),
    )
  }

  let total = 0;
  let score = 0;
  let feedbackItems =  div(
    {class: "w-full flex flex-col gap-1 h-full"},
    t(
      {class: "font-rope font-semibold text-lg"},
      "Deductions by Question"
    ) 
  );
  let transcriptItems = div(
    {class: "w-full flex flex-col gap-1"},
    t(
      {class: "font-rope font-semibold text-lg"},
      "Strategic Analysis"
    ) 
  );
  console.log(feedbackData)

  let merged = []
  for (const [rKey, listed] of Object.entries(feedbackData["transcript_feedback"])){
    merged = [...merged, ...listed]
  }

  console.log(merged)

  for (let i = 0; i < feedbackData["chat_history_list"].length; ++i){
    let curr = feedbackData["chat_history_list"][i];
    if ((i & 1) && (i/2 < merged.length)){
      let badMove = merged[Math.floor(i/2)].result.trim().toLowerCase() == "bad move";
      let goodMove = merged[Math.floor(i/2)].result.trim().toLowerCase() == "good move";
      let okMove = merged[Math.floor(i/2)].result.trim().toLowerCase() == "ok move";
      van.add(transcriptItems, div(
        {class: `flex flex-col ${badMove ? "border-r-fire border-r-4" : ""} ${goodMove ? "border-r-asparagus border-r-4" : ""} ${okMove ? "border-r-gray-400 border-r-4" : ""}`},
        t(
        {class: "font-rope"},
        span(
          {class: "font-semibold"},
          "You: "
        ),
        formatMessageForChat(curr)
      ), t(
        {class: `font-rope ${badMove ? "text-fire": ""} ${goodMove ? "text-asparagus": ""} ${okMove ? "text-gray-600": ""}`},
        merged[Math.floor(i/2)].explanation
      )

      ))
    }
    else{
      van.add(transcriptItems, t(
        {class: "font-rope"},
        span(
          {class: "font-semibold"},
          "Interviewer: "
        ),
        formatMessageForChat(curr)
      ));
    }
  }

  for (const [rubricKey, feedbackList] of Object.entries(feedbackData["rubric_feedback"])) {

    van.add(feedbackItems, t(
      {class: "font-rope font-semibold"},
      `Question #${rubricKey}`
    ))
  
    let addedAny = false;
    for (let feedbackObj of feedbackList){
      total += Math.abs(parseInt(`${feedbackObj.deduction}`))
      score += Math.abs(parseInt(`${feedbackObj.deduction}`))
      if (feedbackObj.result){
        addedAny = true;
        score -= Math.abs(parseInt(`${feedbackObj.deduction}`))
        van.add(feedbackItems, FeedbackRow({
          criterion: feedbackObj.criterion,
          score:  `-${feedbackObj.deduction}`,
          description: feedbackObj.explanation
        }))
      }
    }
    if (!addedAny){
      van.add(feedbackItems, t(
        {class: "font-rope"},
        "No deductions"
      ),)
    }
  }
  return div(
    {class: "flex flex-col items-center h-[90%]"},
    div({class: "h-5"}),
    div(
      {class: "flex max-w-[90%] gap-5 flex-grow-0 h-full flex-wrap sm:flex-nowrap  overflow-y-auto"},
      div({id: "feedbackContent", class: "flex-col h-full sm:basis-1/2 sm:flex-grow-0 w-full sm:w-0"},
        div({class: "flex border-steel border-4 rounded-lg p-4 justify-center"},
          h1(
            {class: "font-rope font-bold text-4xl"},
            `Final Score: ${score} / ${total}`
          ),
        ),
        div({class: "h-5"}),
        div({class: "w-full border-steel border-4 rounded-lg p-4 h-[80%] overflow-auto"},
          feedbackItems
        ),
        div({class: "h-4"}),
        
        
      ),
      div(
        {id: "feedbackContent", class: "flex-col basis-1/2 flex-grow-0 gap-5 h-full"},
        div(
          {class: "border-4 border-steel rounded-lg p-4 flex-col"},
          responseTimeDisplayed,
          div(
            {class: "w-full flex justify-center"},
            canvas({
              id:"chart",
              class: "w-[400px] h-[200px]"
            }),
          ),
        ),
        div({class: "h-5"}),
        div({class: "w-full flex flex-col gap-1 border-steel border-4 rounded-lg p-4 overflow-auto"},
          t(
            {class: "font-rope font-semibold text-lg"},
            "Mannerisms Analysis"
          ),
          () => mannerismData['cameraOn'] ? t({class: "text-semibold font-rope"}, `👀 Eye Contact: ${mannerismData['eyeContactPercent']}%`) : "",
          () => mannerismData['cameraOn'] ? t({class: "text-semibold font-rope"}, `😊 Smiling: ${mannerismData['smilePercent']}%`) : "",
          () => !mannerismData['cameraOn'] ? t({class: "text-semibold font-rope"}, isMobileOrTablet() ?"Use a video case with your camera on to receive feedback on your mannerisms! (not available on mobile devices)" : "Use a video case with your camera on to receive feedback on your mannerisms!") : ""
        ),
        div({class: "h-5"}),
        div({class: "w-full flex border-steel border-4 rounded-lg p-4 overflow-auto h-[50%]"},
          transcriptItems
        ),
      )
    ),
    div({class: "h-5"}),
    () => !isSignedIn() ? div(
      {class: "flex justify-center"},
      SmallLinkButton({
        "onclick": signInUserWithGoogle,
        "text": "Log in to save your progress",
      })
    ) : "",
    div(
      {class: "flex justify-centerm"},
      PrimaryButton({
        href: "/",
        text: "Back"
      })
    ),
  )
  
  
  
  
  
}

export {FeedbackDisplay}