import van from "vanjs-core"
import PrimaryButton from "./PrimaryButton";
import { route } from "./Router";
import { globalInterviewer, globalTimerEnabled, specifiedCaseName, specifiedCaseNumber, specifiedCaseId, globalPauseTime } from "../globals";
import isMobileOrTablet from "../helpers/checkDevice";

const {div, button, t, span, input} = van.tags;


const Divider = (label) => {
  return div(
        {class: "pb-1 border-steel border-b-4 w-[50%]"},
        t({class: "font-semibold text-lg"}, label),
      );
}

const InterviewerAvatar = (icon, selected, id, onSelect) => {
  return button(
    {
      onclick: () => {
        onSelect(id);
      }
    },
    div(
      {class: `w-14 h-14 rounded-full ${selected ? "border-blue-500" : "border-steel"} !text-4xl flex justify-center items-center ${selected ? "!text-blue-500" : "!text-gray-400"}  border-4`},
      span(
        {class: "material-symbols-outlined"},
        icon
      )
    )
  );
}

const interviewerNames = [ "Joe" , "Chloe" , "Karen" ,"Goggins", "Random"];
const interviewerDescriptions = ["Just a chill guy.", "A bit quirky and goes off the script", "You're in for a lot of nitpicking", "The toughest out there.", "Could have any personality traits."];



const StartCaseModal = ({onCancel, onConfirm, label, caseId}) => {

  let video = van.state(!isMobileOrTablet());
  let avatarController = van.state([true, false, false, false, false]);
  let selectedAvatarIndex = van.state(0);

  let timerChecked = van.state(false);

  let maxPauseInput = input({class: "p-2 border-steel border-2 w-12 rounded-lg", value: 1.0, type:"number"})   

  function avatarCallback(id){
    let newState = [false, false, false, false, false]
    newState[id] = true;
    selectedAvatarIndex.val = id;
    avatarController.val = newState;
  }

  return div(
    {class: "modal w-full font-rope h-full flex justify-center items-center z-10 absolute top-0 left-0 bg-dark bg-opacity-50", style: "backdrop-filter: blur(10px);"},
    div({class: "w-[80%] space-y-5 max-w-[500px] flex flex-col p-5 bg-light border-steel border-4 rounded-lg"},
      div(
        {class: "flex w-full items-center"},
        t({class: "text-3xl font-semibold font-rope"}, "Before You Begin..."),
        div({class: "flex-grow"}),
        span(
          {
            class: "material-symbols-outlined !text-3xl hover:text-fire hover:cursor-pointer",
            onclick: onCancel
          },
          "close"
        )
      ),
      div(
        {class: "flex gap-2"},
        t({class: "font-semibold text-lg"}, "Case: "),
        t({class: "font-normal text-lg"}, label)
      ),
      Divider("Format"),
      div(
        {class: "flex justify-center items-center"},
        () => button(
          {
            class: `p-2 hover:cursor-pointer ${video.val ? "border-blue-500" : "border-steel"}  border-4 border-r-blue-500 border-r-[2px] rounded-tl-lg w-28 h-28 ${video.val ? "!text-blue-500" : "!text-gray-400"} rounded-bl-lg bg-white`,
            onclick: () => {
              // if (isMobileOrTablet()){
              //   alert("Video Call Mode not available on mobile devices.")
              // }
              // else{
              //   video.val = true
              // }
              video.val = true
            }
          },
          div(
            {class: "flex flex-col gap-2"},
            span(
              {class: "material-symbols-outlined !text-4xl"},
              "video_call"
            ),
            t({class: "font-rope text-sm"}, "Full Video Call")
          )
        ),
        () => button(
          {
            class: `hover:cursor-pointer p-2 ${!video.val ? "border-blue-500" : "border-steel"} border-l-blue-500 border-4 border-l-[2px] w-28 h-28 rounded-tr-lg rounded-br-lg bg-white ${!video.val ? "!text-blue-500" : "!text-gray-400"}`,
            onclick: () => video.val = false
          },
          div(
            {class: `flex flex-col gap-2 ${video.val ? "!text-gray-400" : "!text-darkl"}`},
            span(
              {class: `material-symbols-outlined !text-4xl`},
              "forum"
            ),
            t({class: "font-rope text-sm"}, "Chat Only")
          )
        )
      ),
      // Divider("Interviewer"),
      // div(
      //   {class: "flex justify-center gap-8"},
      //   () => InterviewerAvatar("face_6", avatarController.val[0], 0, avatarCallback),
      //   () => InterviewerAvatar("face_2", avatarController.val[1], 1, avatarCallback),
      //   () => InterviewerAvatar("face_3", avatarController.val[2], 2, avatarCallback),
      //   () => InterviewerAvatar("face_5", avatarController.val[3], 3, avatarCallback),
      //   () => InterviewerAvatar("psychology_alt", avatarController.val[4], 4, avatarCallback),
      // ),
      // div(
      //   {class: "flex gap-2"},
      //   () => t({class: "font-rope font-semibold"}, `${interviewerNames[selectedAvatarIndex.val]}`),
      //   () => t({class: "font-rope"}, `${interviewerDescriptions[selectedAvatarIndex.val]}`),
      // ),
      Divider("Misc."),
      () => button(
        { 
          class: `flex w-full justify-between flex-grow gap-5 bg-white hover:bg-steel ${timerChecked.val ? "border-blue-500" : "border-steel"} border-4 p-2 rounded-lg`,
          onclick: () => {
            timerChecked.val = !timerChecked.val;
          }
        },
        t({class: "font-rope font-semibold"}, "Show Timer"),
        () => !timerChecked.val ? 
          span(
            {class: "material-symbols-outlined !text-gray-400"},
            "circle"
          ) : 
          span(
            {class: "material-symbols-outlined !text-blue-500"},
            "check_circle"
          )
      ),
      () => !isMobileOrTablet() ? div(
        {class: "flex flex-col gap-1"},
        div(
          {class: "flex gap-2 items-center"},
          t({class: "font-semibold font-rope text-md"}, "Max Pause Time (sec): "),
          maxPauseInput
        ),
        t({class: "text-xs font-rope"}, "Controls how long of a pause video interviewer waits for before talking. Lower number means you'll hear back faster but have a higher chance of being interrupted if you don't talk smoothly."),
      ) : "",
      
      

      // div({class: "grid space-y-2"},
      //   label({for: "hs-vertical-checkbox-in-form", class: "max-w-xs flex p-3 w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400"},
      //     input({type: "checkbox", class: "shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800", id: "hs-vertical-checkbox-in-form"}),
      //     span({class: "text-sm text-gray-500 ms-3 dark:text-neutral-400"},
      //       "Default checkbox",
      //     ),
      //   ),
      //   label({for: "vertical-checkbox-checked-in-form", class: "max-w-xs flex p-3 w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400"},
      //     input({type: "checkbox", class: "shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800", id: "vertical-checkbox-checked-in-form", checked: ""}),
      //     span({class: "text-sm text-gray-500 ms-3 dark:text-neutral-400"},
      //       "Checked checkbox",
      //     ),
      //   ),
      // ),

      div(
        {class: "flex w-full justify-center"},
        PrimaryButton({
          text: "Start Case",
          onclick: () => {
           // globalInterviewer.val = interviewerNames[selectedAvatarIndex.val];
            specifiedCaseName.val = label;
            globalPauseTime.val = parseInt(maxPauseInput.value);
            globalTimerEnabled.val = timerChecked.val;
            function extractNumber(inputString) {
                const match = inputString.match(/^(\d+)\./);
                return match ? parseInt(match[1], 10) : null;
            }
            specifiedCaseNumber.val = extractNumber(label);
            specifiedCaseId.val = caseId;
            if (video.val){
              route("/videocase");
            }
            else{
              route("/case");
            }
          } 
        }),
      )
    ),
  )
}

export default StartCaseModal;